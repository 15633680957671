<template>
  <el-container style="height: 800px; border: 1px solid #eee">
    <el-header style="text-align: right; font-size: 12px">
      <el-col :md="2">
        <ul class="el-menu--horizontal el-menu">
          <li class="el-menu-item">
            <a href="/post" style="text-decoration-line: none">
              <img src="@/assets/img/icon/logo.png" class="el-avatar--circle el-avatar--medium" alt="img">
              MyPost
            </a>
          </li>
        </ul>
      </el-col>
      <el-dropdown>
        <img
          :src="user.avatarUrl"
          class="el-avatar--circle el-avatar--medium"
          style="margin-right: 10px; margin-top: 15px"
          alt=""
        >
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            icon="el-icon-s-platform"
            class="size"
            @click.native="goToHome"
          >主站</el-dropdown-item>
          <el-dropdown-item
            icon="el-icon-error"
            class="size"
            @click.native="goToLogout"
          >退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu
          :default-active="this.$route.path"
          router
          class="el-menu-vertical-demo"
          :unique-opened="true"
        >
          <el-submenu index="/post/video">
            <template slot="title">
              <i class="el-icon-files" />
              <span slot="title">稿件管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/post/video/list">
                <i class="el-icon-film" />
                <span slot="title">视频稿件</span>
              </el-menu-item>
              <el-menu-item index="/post/image/list">
                <i class="el-icon-picture" />
                <span slot="title">图片稿件</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/post/album">
            <i class="el-icon-collection" />
            <span slot="title">播放列表</span>
          </el-menu-item>
          <el-menu-item index="/post/history">
            <i class="el-icon-s-data" />
            <span slot="title">历史记录</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import { getAuthedUser } from '@/utils/auth'

export default {
  name: 'Post',
  mixins: [userMixin],
  data() {
    return {
      user: null,
      loginUser: null
    }
  },
  created() {
    document.title = '我的稿件'
    const userInfo = getAuthedUser()
    if (userInfo !== null) {
      this.user = userInfo
      this.loginUser = userInfo
    }
  }
}
</script>

<style>
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
